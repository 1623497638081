/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import has from "../../../core/has.js";
import { Milliseconds as o } from "../../../core/time.js";
function r() {
  const r = has("mapview-essential-goto-duration");
  return null == r ? r : o(r);
}
export { r as getGoToDuration };